<template>
  <div class="overflow-auto pb-5" v-if="lineItemHasAllProperties">
    <div class="">
      <div class="d-flex align-items-center  min-w-250px px-3 py-3 mb-0">
        <img :src="image" class="me-2 opacity-75 w-75px" />
        <a href="#" class="fs-5 text-dark text-hover-primary fw-bold">{{ title }}</a>
        <span class="ms-5 text-muted fs-3">X</span>
        <span class="ms-3 fw-bold">{{ quantity }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lineItem() {
      return this.event.lineItem
    },
    lineItemHasAllProperties() {
      try {
        return this.title && this.quantity
      } catch (e) {
        return false
      }
    },
    title() {
      return this.lineItem && this.lineItem.title
    },
    sku() {
      return this.lineItem && this.lineItem.sku
    },
    image() {
      return this.event.payload.shopifyLineItem && this.event.payload.shopifyLineItem.image
    },
    quantity() {
      return this.lineItem && this.lineItem.quantity
    }
  }
}
</script>
