<template>
  <b-overlay :show="state.isLoading" rounded="sm">
    <div class="card">
      <div class="card-header card-header-stretch">
        <div class="card-title d-flex align-items-center">
          <h3 class="fw-bold m-0 text-gray-800" v-if="state.purchaseEvent">
            Order #{{ state.purchaseEvent.shopify_order.order_number }}
<!--            <a href="">-->
<!--              <i class="fas fa-external-link-alt ms-1 fs-8"></i>-->
<!--            </a>-->
          </h3>
        </div>
      </div>
      <div class="card-body" v-if="state.purchaseEvent">
        <div class="timeline">
          <div class="timeline-day" v-for="(events, day) in state.purchaseEvent.journey" :key="day">
            <StickyElement visibleOnDirection="disabled" class="mb-5">
              <h5 class="bg-light p-3">{{day}}</h5>
            </StickyElement>
            <JourneyEvent v-for="(event, index) in events" :event="event"
                          :key="index"></JourneyEvent>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import JourneyEvent from "@/views/orders/JourneyEvent";
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import dayjs from "dayjs";
import {groupBy} from 'lodash';
import StickyElement from 'vue-sticky-element';


export default {
  components: {
    JourneyEvent,
    StickyElement
  },
  data() {
    return {
      pageViewEvent: {
        type: 'PageView',
        time: new Date(),
        url: 'http://something.local:8080',
      },
    }
  },
  setup() {
    const state = reactive({
      purchaseEvent: null,
      isLoading: true,
      orderId: null
    });
    const route = useRoute()

    onMounted(async () => {
      state.orderId = route.params.id
      axios.get('/order/' + state.orderId)
        .then((response) => {
console.log(response.data.data)
          state.purchaseEvent = response.data.data
          // group journey events by date
          let journey = groupBy(state.purchaseEvent.journey, (event) => dayjs(event.context.timing.gcf_recieved_at).format('dddd, MMMM D, YYYY'))
          state.purchaseEvent.journey = journey
          state.isLoading = false
        })
        .catch(() => PopupMessage.show('Failed to fetch order journey', 'error', 'Ok'))
    });

    return {
      state,
    };
  },
}
</script>

<style scope>
.vue-sticky-element--stuck {
  top: 55px!important;
}
</style>