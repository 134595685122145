<template>
  <div class="timeline-item pb-3">
    <div class="timeline-line w-40px"></div>
    <div class="timeline-icon symbol symbol-circle symbol-40px">
      <div class="symbol-label bg-light"><span class="svg-icon svg-icon-2 svg-icon-gray-500">
              <i class="fas fs-4" :class="icon"></i>
            </span></div>
    </div>
    <div class="timeline-content mb-10">
      <div class="overflow-auto pe-3">
        <div class="fs-5 fw-bold mb-2">
          {{ $t('eventTypes.' + type) }} <span class="text-muted me-2 fs-7">{{ time }}</span>
        </div>
        <div class="d-flex align-items-center mt-1 fs-6">
          <a :href="current_url" target="_blank" class="text-primary text-primary-hover me-2 fs-6">
            {{ current_url }}
            <i class="fas fa-external-link-alt ms-1 fs-8"></i>
          </a>
        </div>
        <component :is="type" :event="event"/>
      </div>
      <div class="sources">
        <div v-for="(val, key) in sources" :key="key" class="d-flex align-items-center mt-1 fs-6">
          <span class="source-name fw-bolder me-2">{{ key.replace('utm_', '') }}:</span>
          <span>{{ val }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from "dayjs";
import AddToCart from "@/views/orders/AddToCart";

export default {
  components: {
    AddToCart
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      let map = {
        PageView: 'fa-eye',
        AddToCart: 'fa-cart-arrow-down',
        InitiateCheckout: 'fa-cash-register',
        AddShippingInfo: 'fa-truck',
        AddPaymentInfo: 'fa-credit-card',
        Purchase: 'fa-shopping-bag',
        ViewCart: 'fa-eye',
        ViewContent: 'fa-tshirt',
        default: 'fa-eye'
      }
      return map[this.event.event_name] || map.default
    },
    type() {
      return this.event.event_name
    },
    time() {
      return dayjs(this.event.context.timing.gcf_recieved_at).format('h:mm:ss A')
    },
    current_url() {
      return this.event.context.current.url.path
    },
    sources() {
      return Object.keys(this.event.context.current.url.query)
        .filter(key => ['utm_source', 'utm_campaign', 'utm_term', 'utm_content', 'fbadid'].includes(key))
        .reduce((obj, key) => {
          obj[key] = this.event.context.current.url.query[key];
          return obj;
        }, {})
    }
    // getEventTime() {
    //   return this.event?.context?.timing?.gcf_recieved_at ? dayjs(this.event?.context?.timing?.gcf_recieved_at).format('h:mm:ss A') : 'N/A';
    // }
  }
}
</script>

<style scoped>
  .source-name {
    text-transform: capitalize;
  }
</style>